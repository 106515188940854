<template>
<div>
	<div style="display: none;" :class="{show: ! active && g.owner}">
		<a class="btn sub" @click="getActive">グループ名変更</a>
	</div><!--menu-->
	<div class="detail" :class="{show: active && g.owner}">
		<a class="btn cancel" @click="active=false">戻る</a>
		<p class="message">{{message}}</p>
		<input type="text" v-model="name" placeholder="変更するグループ名">
		<a class="btn" @click="submit">変更する</a>
	</div><!--detail-->
</div>
</template>

<script>
import {post} from '../../lib/utils'
export default {
	props:{
		g: {
			type: Object
		},
	},

	data: function(){
		return {
			message: '',
			active: false,
			name: '',
		}
	},

	mounted: async function(){
	},

	methods: {
		getActive: async function(){
			this.name = this.g.name
			this.active = true
		},

		submit: async function(){
			let res = await post('/webapi/group/update', {gid: this.g.gid, name: this.name})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.active = false
		},

	},
}
</script>

<style scoped>
.detail{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ccc;
	z-index: 20;
}
</style>
