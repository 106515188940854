<template>
<div>
	<p>{{message}}</p>
	<div style="display: none;" :class="{show: ! active && g.owner}">
		<a class="btn sub" @click="getInviteUrl">グループ招待</a>
	</div><!--menu-->
	<div style="display: none;" :class="{show: active && g.owner}">
	</div><!--detail-->
</div>
</template>
<script>
import {post} from '../../lib/utils'
export default {
	props:{
		g: {
			type: Object
		},
	},

	data: function(){
		return {
			message: '',
			active: false,
		}
	},

	mounted: async function(){
	},

	methods: {
		getInviteUrl: async function(){
			let res = await post('/webapi/group/invite_url', {gid: this.g.gid})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			navigator.clipboard.writeText(res.url)
			this.message = res.url
		},
	},
}
</script>
<style scoped>
</style>
