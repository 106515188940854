import { render, staticRenderFns } from "./GroupDelete.vue?vue&type=template&id=003640da&scoped=true&"
import script from "./GroupDelete.vue?vue&type=script&lang=js&"
export * from "./GroupDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003640da",
  null
  
)

export default component.exports