<template>
<ul v-show="showMenu()">
	<li @click="linkTo(1)" :class="{active: mid == 1}">メニュー1</li>
	<li @click="linkTo(2)" :class="{active: mid == 2}">メニュー2</li>
	<li @click="linkTo(3)" :class="{active: mid == 3}">メニュー3</li>
</ul>
</template>

<style scoped>
ul{
	position: fixed;
	z-index: 20;
	width: 150px;
	height: 60px;
	bottom: 0;
	left: 0;
	background: #fcfcfc;
}
li{
	display: block;
	float: left;
	width: calc(100% / 3);
	height: 100%;
	line-height: 60px;
	text-align: center;
	cursor: pointer;
}
li.active{
	background: var(--col-main);
	color: white;
}
</style>

<script>
export default {
	data: function(){
		return {
			mid: 1,
		}
	},
	methods: {
		linkTo: function(mid){
			this.mid = mid
			this.$router.push({name: 'Home'})
			console.log(mid)
		},

		showMenu: function(){
			if(this.$route.name == 'Index') return false
			if(this.$route.name == 'Index2') return false
			return true
		},
	},
}
</script>
