export default {
	data: function(){
		return {
			isReady: false,
			message: '',
		}
	},

	mounted: async function(){
		let res = await this.$post('/webapi/user/auth', {hash_value: this.$route.params.hash_value})
		if(res.code != 0){
			this.message = res.message
			this.isReady = true
			return false
		}
		this.$router.push({name: 'Home'})
	},

	methods: {
	},

	components: {
	},

}
