export default {
	data: function(){
		return {
			isReady: false,
			message: '',
			group: {},
			auth: false,
		}
	},

	mounted: async function(){
		//認証チェック
		let res = await this.$post('/webapi/user/index')
		if(res.code == 0) this.auth = true

		res = await this.$post('/webapi/group/invite', {hash_value: this.$route.params.hash_value})
		if(res.code != 0){
			this.message = res.message
			this.isReady = true
			return false
		}
		this.group = res.group
		this.isReady = true
	},

	methods: {
		createUser: async function(){
			let res = await this.$post('/webapi/user/create')
			if(res.code != 0){
				this.message = res.message
				return false
			}
			await this.addGroupMember()
		},

		addGroupMember: async function(){
			let res = await this.$post('/webapi/group_member/create', {hash_value: this.$route.params.hash_value})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.$router.push({name: 'Home'})
		},

	},

	components: {
	},

}
