<template>
<div>
	<p class="message">{{message}}</p>
	<div style="display: none;" :class="{show: ! active && g.owner}">
		<a class="btn sub" @click="wrapDeleteFunction">グループ削除</a>
	</div><!--menu-->
	<div class="detail" :class="{show: active && g.owner}">
	</div><!--detail-->
</div>
</template>

<script>
import {post} from '../../lib/utils'
export default {
	props:{
		g: {
			type: Object
		},
		deleteGroup: {
			type: Function
		},
	},

	data: function(){
		return {
			message: '',
			active: false,
		}
	},

	mounted: async function(){
	},

	methods: {
		wrapDeleteFunction: async function(){
			await this.deleteGroup()
		},
	},
}
</script>

<style scoped>
</style>
