import axios from 'axios'

export async function post(url, data, addHeaders={}, method='post'){
	let headers = {
		'Content-Type'     : 'application/json',
		'pragma'           : 'no-cache',
		'Cache-Control'    : 'no-cache',
		'If-Modified-Since': 'Thu, 01 Jun 1970 00:00:00 GMT',
	}

	//jwtを設定
	let jwt = localStorage.getItem('fs-auth')
	headers['fs-auth'] = jwt

	//ヘッダ追加分
	for(let key in addHeaders){
		headers[key] = addHeaders[key]
	}
	
	return await axios({
		method: method,
		url:  url + '.json',
		data: data,
		headers: headers,
		responseType: 'json',
	})
	.then(function(res){
		if(res.headers['fs-auth'] != null) localStorage.setItem('fs-auth', res.headers['fs-auth'])
		if(res.headers['fs-auth-del'] != null) localStorage.removeItem('fs-auth')
		return res.data
	})
	.catch(function(e){
		console.warn(e)
		if(e.response.data.code){
			return e.response.data
		}else{
			return {code: 500, message: 'ネットワークの接続に失敗しました。再度実施してください。'}
		}
	})
}


/*3桁カンマ区切り＋小数点2桁*/
export function comma(num, dispDec=2) {
	let strs = String(num).split('.')
	let str_int = strs[0].replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
	let zeroPad = '0'.repeat(dispDec)
	if(dispDec == 0){
		return [str_int]
	}else{
		let str_dec = strs.length < 2 ? zeroPad : (strs[1] + zeroPad).slice(0, dispDec)
		//加工用に配列で返す
		return [str_int, str_dec]
	}
}

/*日付フォーマット*/
export function dtfmt(dt){
	let d = new Date(dt)
	let y = d.getFullYear()
	let m   = '0' + (d.getMonth() + 1)
	let day = '0' + d.getDate()
	return y + '-' + m.substr(-2) + '-' + day.substr(-2)
}

