import Vue from 'vue'
import {post} from '../lib/utils'
import VueGtm from '@gtm-support/vue2-gtm'
import App from '../main/app.vue'
import router from '../main/router'

Vue.config.productionTip = false

/*global variables and methods*/
Vue.prototype.$RCONF = RCONF

/*if(RAILSENV == 'production' || RAILSENV == 'staging'){
Vue.use(VueGtm, {
	id: RCONF.gtm_id
})
}*/

Vue.prototype.$post = post

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
		router,
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)
  console.log(app)
})

