import FixedMenu from './FixedMenu.vue'

export default {
	data: function(){
		return {
			isReady: false,
		}
	},

	mounted: async function(){
		this.isReady = true
	},

	methods: {
	},

	components: {
		FixedMenu,
	},

}
