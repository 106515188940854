import GroupInvite from '../../components/GroupInvite.vue'
import GroupMembers from '../../components/GroupMembers.vue'
import GroupChangeName from '../../components/GroupChangeName.vue'
import GroupDelete from '../../components/GroupDelete.vue'

export default {
	data: function(){
		return {
			isReady: false,
			message: '',
			user: {},
			name: '',
			groups: [],
			can_submit_search: false,
			email: '',
			nickname: '',
			selected: {g: {}, index: -1 },
			menuMode: 'none',
			data_objects: [],
			disp_objects: [],
		}
	},

	mounted: async function(){
		//ユーザ情報取得（sessionStorageから）
		let res = await this.$post('/webapi/user/index', {})
		if(res.code != 0){
			this.message = res.message
			return false
		}
		this.user = res.user

		//グループ情報取得
		res = await this.$post('/webapi/group/index', {})
		if(res.code != 0){
			this.message = res.message
		}else{
			this.groups = res.groups
			if(this.groups.length > 0){
				this.selected = {g: this.groups[0], index: 0}
				await this.selectChanged()
			}
		}

		this.isReady = true
	},

	methods: {
		createGroup: async function(){
			let res = await this.$post('/webapi/group/create', {name: this.name})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.groups.push(res.group)
			let idx = this.groups.length -1
			this.selected = {g: this.groups[idx], index: idx}
			await this.selectChanged()
		},

		deleteGroup: async function(){
			let res = await this.$post('/webapi/group/delete', {gid: this.selected.g.gid})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.groups.splice(this.selected.index, 1)
			if(this.groups.length > 0){
				this.selected = {g: this.groups[0], index: 0}
				await this.selectChanged()
			}
		},

		deleteUser: async function(){
			let res = await this.$post('/webapi/user/delete')
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.$router.push({name: 'Index'})
		},

		enable_submit: function(){
			this.can_submit_search = true
		},

		submit: async function(){
			if( ! this.can_submit_search) return
			await this.createGroup()
			this.can_submit_search = false
		},

		updateEmail: async function(){
			let res = await this.$post('/webapi/user/update', {email: this.email})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.user = res.user
		},

		updateNickname: async function(){
			let res = await this.$post('/webapi/user/update', {nickname: this.nickname})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.user = res.user
		},

		logout: async function(){
			let res = await this.$post('/webapi/user/logout')
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.$router.push({name: 'Index'})
		},

		selectChanged: async function(){
			let res = await this.$post('/webapi/data_object/index', {gid: this.selected.g.gid})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.data_objects = res.data_objects
			this.createDispObjects()
		},

		openGroupMenu: function(){
			this.menuMode = 'group'
		},
		
		openGlobalMenu: function(){
			this.menuMode = 'global'
		},

		upload: async function(e){
			let files = e.target.files || e.dataTransfer.files
			for(let i=0, l=files.length; i<l; i++){
				let pic = await this.getBase64(files[i])
				let d = {
					gid:         this.selected.g.gid,
					object_type: 1,
					b64_url:     pic,
				}
				let res = await this.$post('/webapi/data_object/create', d)
				if(res.code != 0){
					this.message = res.message
					return false
				}
			}
		},

		getBase64: function(file){
			return new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => resolve(reader.result)
				reader.onerror = error => reject(error)
			})
		},

		createDispObjects: function(){
			this.disp_objects = []
			for(let i=0,l=this.data_objects.length; i<l; i++){
				let dobj = this.data_objects[i]
				this.disp_objects.push(dobj)
			}
		},

	},

	components: {
		GroupInvite,
		GroupMembers,
		GroupChangeName,
		GroupDelete,
	},

}
