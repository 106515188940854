import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index/Index.vue'
import Home from '../views/Home/Home.vue'
import EmailAuth from '../views/EmailAuth/EmailAuth.vue'
import Invite from '../views/Invite/Invite.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
    name: 'Index2',
    component: Index,
	},
	{
		path: '/index',
    name: 'Index',
    component: Index,
	},
	{
		path: '/home',
    name: 'Home',
    component: Home,
	},
	{
		path: '/email_auth/:hash_value',
    name: 'EmailAuth',
    component: EmailAuth,
	},
	{
		path: '/invite/:hash_value',
    name: 'Invite',
    component: Invite,
	},
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
	scrollBehavior () {
		return {x: 0, y: 0}
	},
})

export default router

