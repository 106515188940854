export default {
	data: function(){
		return {
			isReady: false,
			message: '',
			email: '',
		}
	},

	mounted: async function(){
		let res = await this.$post('/webapi/user/index', {})
		if(res.code == 0){
			this.$router.push({name: 'Home'})
			return true
		}

		this.isReady = true
	},

	methods: {
		createUser: async function(){
			let res = await this.$post('/webapi/user/create', {})
			if(res.code == 0){
				this.$router.push({name: 'Home'})
				return true
			}
			console.log(res)
		},

		emailAuth: async function(){
			let res = await this.$post('/webapi/user/email_auth', {email: this.email})
			if(res.code != 0){
				this.message = res.message
				return false
			}
			this.message = 'ログインURLをメールに送付しました。'
			return true
		},

	},

	components: {
	},

}
